"use client";

import { Box, Container, Flex, Skeleton } from "@radix-ui/themes";
import React from "react";

function ProfilePageSkeleton() {
  return (
    <Flex direction={"column"}>
      {/* Navigation */}
      <Flex
        justify={"between"}
        px={{ initial: "5", md: "7" }}
        py={{ initial: "5" }}
        width={"100%"}
      >
        <Skeleton height={"25px"} width={"172px"} />
        <Flex gap={"5"} display={{ initial: "none", md: "flex" }}>
          <Skeleton height={"25px"} width={"100px"} />
          <Skeleton height={"25px"} width={"100px"} />
          <Skeleton height={"25px"} width={"70px"} />
          <Skeleton height={"25px"} width={"70px"} />
          <Skeleton height={"25px"} width={"100px"} />
        </Flex>
        <Box display={{ initial: "block", md: "none" }}>
          <Skeleton height={"25px"} width={"25px"} />
        </Box>
      </Flex>

      <Box minHeight={"100vh"} mb={"9"}>
        <Container
          size={"3"}
          width={"100%"}
          maxWidth={"1130px"}
          px={{ initial: "0", sm: "7", md: "9" }}
          m={{ initial: "auto" }}
          mt={{ sm: "130px" }}
        >
          <Flex
            direction={{ initial: "column", sm: "row" }}
            gap={{ initial: "0", sm: "7", md: "85px" }}
          >
            {/* Profile left column */}
            <Box minWidth={"0"} flexGrow={"1"}>
              <Skeleton
                height={{ initial: "auto" }}
                width={{ initial: "100%", sm: "400px" }}
                maxWidth={{ initial: "100%" }}
                style={{ borderRadius: "28px", aspectRatio: "1" }}
              />

              <Box px={{ initial: "5", sm: "0" }}>
                {/* Profile name, pronouns, job title */}
                <Box
                  pt={{ initial: "6", sm: "7" }}
                  pb={{ initial: "5", sm: "6" }}
                >
                  <Skeleton
                    height={{ initial: "30px" }}
                    width={{ initial: "50%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "24px" }}
                    width={{ initial: "45%" }}
                  />
                </Box>

                {/* Profile availability tag */}
                <Box pb={{ initial: "5", sm: "6" }}>
                  <Skeleton
                    height={{ initial: "30px", sm: "40px" }}
                    width={{ initial: "35%" }}
                  />
                </Box>

                {/* Profile professional statement */}
                <Box pb={{ initial: "4", sm: "4" }}>
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "95%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "85%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "75%" }}
                    mb={{ initial: "3" }}
                  />
                </Box>

                {/* Profile specialties */}
                <Box pb={{ initial: "6", sm: "7" }}>
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "110px" }}
                    mb={{ initial: "4" }}
                  />
                  <Flex gap={"2"} wrap={"wrap"}>
                    <Skeleton
                      height={{ initial: "30px", sm: "40px" }}
                      width={{ initial: "90px" }}
                      style={{ borderRadius: "24px" }}
                    />
                    <Skeleton
                      height={{ initial: "30px", sm: "40px" }}
                      width={{ initial: "140px" }}
                      style={{ borderRadius: "24px" }}
                    />
                    <Skeleton
                      height={{ initial: "30px", sm: "40px" }}
                      width={{ initial: "120px" }}
                      style={{ borderRadius: "24px" }}
                    />
                  </Flex>
                </Box>

                {/* Profile booking info mobile */}
                <Flex
                  direction={"column"}
                  display={{ initial: "flex", sm: "none" }}
                  width={{ initial: "100%" }}
                  mt={{ initial: "2" }}
                  mb={{ initial: "7" }}
                >
                  <Skeleton
                    height={{ initial: "440px" }}
                    width={{ initial: "100%" }}
                    style={{ borderRadius: "28px" }}
                  />
                </Flex>

                {/* Profile tab navigation */}
                <Flex
                  gap={{ initial: "20px", sm: "40px" }}
                  mb={{ initial: "5", sm: "6" }}
                  width={"100%"}
                  style={{ borderBottom: "2px solid #D9D9D9" }}
                >
                  <Skeleton height={"20px"} width={"40px"} my={"8px"} />
                  <Skeleton height={"20px"} width={"90px"} my={"8px"} />
                  <Skeleton height={"20px"} width={"60px"} my={"8px"} />
                  <Skeleton height={"20px"} width={"100px"} my={"8px"} />
                </Flex>

                {/* Profile about me section */}
                <Box pb={{ initial: "5", sm: "6" }}>
                  <Skeleton
                    height={{ initial: "30px" }}
                    width={{ initial: "110px" }}
                    mb={{ initial: "6" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "85%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "100%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "90%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "80%" }}
                    mb={{ initial: "6" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "85%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "100%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "80%" }}
                    mb={{ initial: "6" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "85%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "100%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "90%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "80%" }}
                    mb={{ initial: "6" }}
                  />
                  <Skeleton
                    height={{ initial: "30px" }}
                    width={{ initial: "90px" }}
                    mb={{ initial: "6" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "20%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "25%" }}
                    mb={{ initial: "6" }}
                  />
                  <Skeleton
                    height={{ initial: "30px" }}
                    width={{ initial: "110px" }}
                    mb={{ initial: "6" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "50%" }}
                    mb={{ initial: "3" }}
                  />
                  <Skeleton
                    height={{ initial: "16px" }}
                    width={{ initial: "40%" }}
                    mb={{ initial: "3" }}
                  />
                </Box>
              </Box>
            </Box>

            {/* Profile right column */}
            <Flex
              direction={"column"}
              gap={"7"}
              display={{ initial: "none", sm: "flex" }}
              maxWidth={"445px"}
              width={{ initial: "360px", md: "445px" }}
              flexShrink={{ md: "0" }}
            >
              {/* Profile booking info desktop */}
              <Skeleton
                height={{ initial: "440px" }}
                width={{ initial: "100%" }}
                style={{ borderRadius: "28px" }}
              />

              <Skeleton
                height={{ initial: "320px" }}
                width={{ initial: "100%" }}
                style={{ borderRadius: "28px" }}
              />
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Flex>
  );
}

export default ProfilePageSkeleton;
